import request from 'utils/request';
import { LocalStorageKeys } from 'constants/LocalStorageKeys';
const PDFURL = 'https://dev-api-ec2.optohealth.co.uk/';
// const PDFURL = 'https://api.optohealth.co.uk/';
// const PDFURL = 'http://localhost:3000/';

export const downloadPDF = async (state) => {
  if (state?.pdfUrl) {
    console.log('CASE 1 - Have Url');
    var myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyODFmZjNmZmM0OGJiZjQyYTkwZDZkZiIsImVtYWlsIjoiZG9jdG9yb25lQGdtYWlsLmNvbSIsInJvbGUiOiJkb2N0b3IiLCJpYXQiOjE2NTI3NzU1MTd9.njXnP_mWQfCJXuM-LoqMx63_zKZirswBHH2v4Dt8ZlE'
    );
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      userID: state?._id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${PDFURL}clinicalPath/downloadPdfFile`, requestOptions).then((response) => {
      console.log('downloadPdfFile API RESPONSE', { response });

      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `file.pdf`;
          a.click();
        })
        .then(async () => {
          console.log('Calling Deletion');
          let response = await request.post(
            `/users/deleteUserPdfByUserId`,
            { userID: state?._id },
            {
              headers: {
                Authorization: localStorage.getItem(LocalStorageKeys.JWT_TOKEN)
              }
            }
          );
          console.log('Deletion API RESPONSE', { response });
        });
    });
  } else {
    console.log('CASE 2 - No Url');

    var myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyODFmZjNmZmM0OGJiZjQyYTkwZDZkZiIsImVtYWlsIjoiZG9jdG9yb25lQGdtYWlsLmNvbSIsInJvbGUiOiJkb2N0b3IiLCJpYXQiOjE2NTI3NzU1MTd9.njXnP_mWQfCJXuM-LoqMx63_zKZirswBHH2v4Dt8ZlE'
    );
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      userID: state?._id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch(`${PDFURL}clinicalPath/generateUserDataPDF`, requestOptions).then((response) => {
      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `file.pdf`;
          a.click();
        })
        .then(async () => {
          console.log('Calling Deletion');
          let response = await request.post(
            `/users/deleteUserPdfByUserId`,
            { userID: state?._id },
            {
              headers: {
                Authorization: localStorage.getItem(LocalStorageKeys.JWT_TOKEN)
              }
            }
          );
          console.log({ response });
        });
    });
  }
};

export const downloadAsJSONFile = (data, filename) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  if (window.navigator.webkitURL) {
    // Webkit-based browsers
    const blobURL = window.navigator.webkitURL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = filename;
    link.click();
    link.remove();
  } else {
    // Modern browsers
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    link.remove();
  }
};
