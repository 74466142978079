import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UserDetail from 'views/dashboard/users/UserDetail';

// dashboard routing
const AdminDashboard = Loadable(lazy(() => import('views/dashboard/AdminDashboard')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UserListing = Loadable(lazy(() => import('views/dashboard/users/UserListing')));
const DoctorDetail = Loadable(lazy(() => import('views/dashboard/Doctors/DoctorDetail')));
const DoctorListing = Loadable(lazy(() => import('views/dashboard/DoctorList/DoctorListing')));
const DiseaseListing = Loadable(lazy(() => import('views/dashboard/Disease')));
const QuestionsListing = Loadable(lazy(() => import('views/dashboard/Questions')));
const ClinicalPath = Loadable(lazy(() => import('views/dashboard/ClinicalPath')));
const AddClinicalPath = Loadable(lazy(() => import('views/dashboard/ClinicalPath/AddClinicalPath')));
const AppointmentListing = Loadable(lazy(() => import('views/dashboard/AppointmentList/AppointmentListing')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const HospitalListing = Loadable(lazy(() => import('views/dashboard/Hospital/HospitalListing')));
const CombinationListing = Loadable(lazy(() => import('views/dashboard/Combinations/CombinationListing')));
const AllergyAlertListing = Loadable(lazy(() => import('views/dashboard/AllergyAlerts/AllergyAlertListing')));
const CreateEditHospital = Loadable(lazy(() => import('views/dashboard/Hospital/CreateEditHospital')));
const HospitalDetail = Loadable(lazy(() => import('views/dashboard/Hospital/HospitalDetail')));
const HospitalDevices = Loadable(lazy(() => import('views/dashboard/Hospital/HospitalDevicesList')));
const AdminListing = Loadable(lazy(() => import('views/dashboard/Admin/AdminListing')));
const PublicHealthQuestionsListing = Loadable(lazy(() => import('views/dashboard/PublicHealthQuestions/PublicHealthQuestionsListing')));
const EcdsCombinationListing = Loadable(lazy(() => import('views/dashboard/EcdsCombination/EcdsCombinationListing')));

import UserClinicalPathTester from '../views/dashboard/users/_part/ClinicalPathTester';
import QuestionDetails from 'views/dashboard/Questions/QuestionDetails';
import MapTree from 'views/dashboard/Tree/MapTree';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <AdminDashboard />
    },
    {
      path: '/',
      element: <AdminDashboard />
    },
    {
      path: '/utils/util-typography',
      element: <UtilsTypography />
    },
    {
      path: '/userlist',
      element: <UserListing />
    },
    {
      path: '/userdetail',
      element: <UserDetail />
    },
    {
      path: '/doctordetail',
      element: <DoctorDetail />
    },
    {
      path: '/doctorlist',
      element: <DoctorListing />
    },
    {
      path: '/appointmentlist',
      element: <AppointmentListing />
    },
    {
      path: '/utils/util-shadow',
      element: <UtilsShadow />
    },
    {
      path: '/icons/tabler-icons',
      element: <UtilsTablerIcons />
    },
    {
      path: '/tree',
      element: <MapTree />
    },
    {
      path: '/icons/material-icons',
      element: <UtilsMaterialIcons />
    },
    {
      path: '/sample-page',
      element: <SamplePage />
    },
    {
      path: '/pathway-list',
      element: <DiseaseListing />
    },
    {
      path: '/question-list',
      element: <QuestionsListing />
    },
    {
      path: '/question-detail',
      element: <QuestionDetails />
    },
    {
      path: '/clinical-path',
      element: <ClinicalPath />
    },
    {
      path: '/clinical-path/:id',
      element: <UserClinicalPathTester />
    },
    {
      path: '/add-clinical-path',
      element: <AddClinicalPath />
    },
    {
      path: '/hospital',
      element: <HospitalListing />
    },
    {
      path: '/combination',
      element: <CombinationListing />
    },
    {
      path: '/allergyAlert',
      element: <AllergyAlertListing />
    },
    {
      path: '/add-edit-hospital',
      element: <CreateEditHospital />
    },
    {
      path: '/hospital-detail',
      element: <HospitalDetail />
    },
    {
      path: '/hospital-devices',
      element: <HospitalDevices />
    },
    {
      path: '/admin-list',
      element: <AdminListing />
    },
    {
      path: '/public-health-questions',
      element: <PublicHealthQuestionsListing />
    },
    {
      path: '/ecds-combination',
      element: <EcdsCombinationListing />
    },
  ]
};

export default AdminRoutes;
